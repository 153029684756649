<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="text-center pt-3 pb-4">
        <p class="h1">
          W czym możemy Ci pomóc?
        </p></b-col
      >
    </b-row>
    <b-row>
      <b-col>
        <b-card-group columns>
          <b-card
            class="insurance-card p-0 rounded"
            v-for="(insurance, idx) in insurances"
            :key="idx"
            @click="clickedCard(insurance.url)"
          >
            <img
              v-if="insurance.img && insurance.img === 'doctor.jpg'"
              style="max-width: 100%"
              src="@/assets/doctor.jpg"
            />
            <img
              v-if="insurance.img && insurance.img === 'life.jpg'"
              style="max-width: 100%"
              src="@/assets/life.jpg"
            />
            <img
              v-if="insurance.img && insurance.img === 'car.jpg'"
              style="max-width: 100%"
              src="@/assets/car.jpg"
            />
            <img
              v-if="insurance.img && insurance.img === 'phone.jpg'"
              style="max-width: 100%"
              src="@/assets/phone.jpg"
            />
            <img
              v-if="insurance.img && insurance.img === 'holiday.jpg'"
              style="max-width: 100%"
              src="@/assets/holiday.jpg"
            />
            <img
              v-if="insurance.img && insurance.img === 'bike.jpg'"
              style="max-width: 100%"
              src="@/assets/bike.jpg"
            />

            <b-card-body class="text-center">
              <b-card-title>{{ insurance.text }}</b-card-title>

              <b-card-text>
                <p v-html="insurance.description"></p>
              </b-card-text>
            </b-card-body>
          </b-card> </b-card-group
      ></b-col>
    </b-row>

    <img
      class="office-img"
      alt="Ubezpieczenia MS Konsulting"
      src="@/assets/office.jpg"
      v-show="false"
    />
  </b-container>
</template>

<script>
export default {
  name: "Home",
  methods: {
    clickedCard(url) {
      this.$router.push(url);
    },
  },
  computed: {
    insurances() {
      return [
        {
          img: "doctor.jpg",
          text: "Ubezpieczenia medyczne",
          url: "/ubezpieczenia/medyczne",
          description:
            "W naszej ofercie znajdziesz produkty liderów ubezpieczeń dla branży medycznej na polskim rynku, " +
            "tj. PZU S.A., Allianz oraz INTER Polska S.A",
        },
        {
          img: "life.jpg",
          text: "Ubezpieczenia na życie",
          url: "/ubezpieczenia/na-zycie",
          description: "Indywidualne i grupowe ubezpieczenia na życie",
        },
        {
          img: "car.jpg",
          text: "Ubezpieczenia komunikacyjne",
          url: "/ubezpieczenia/komunikacyjne",
          description: "OC, AC, NNW, ASS, Zielona Karta",
        },
        {
          img: "holiday.jpg",
          text: "Ubezpieczenia turystyczne",
          url: "/ubezpieczenia/turystyczne",
          description: "Ubezpieczenie w podróży i podczas pobytu za granicą",
        },
        {
          img: "phone.jpg",
          text: "Skontaktuj się z nami",
          url: "/kontakt",
          description: "Pomożemy Ci wybrać najlepszą ofertę",
        },
        {
          img: "bike.jpg",
          text: "Ubezpieczenia dla rowerzystów",
          url: "/ubezpieczenia/rower",
          description: "Ubezpieczenie OC/NNW rowerzysty",
        },
      ];
    },
  },
};
</script>

<style scoped>
.insurance-card {
  cursor: pointer;
  background-color: #fafafaee;
  opacity: 0.8;
}

.insurance-card:hover {
  opacity: 1;
}

.card-title {
  font-size: 1.4rem;
  font-style: "bold";
  padding: 20px 10px 0px 5px;
}

.card-body {
  padding: 0px 0px 0px 0px !important;
}

.card-text {
  font-size: 1rem;
  padding: 10px !important;
}
</style>
